import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { lowerCase, map, includes, filter, last } from "lodash";
import { Typography, Divider } from "@material-ui/core";
import { AutoComplete } from "components/inputs";
import { fetchFormularies } from "app/main/orgUnits/actions/orgUnit.actions";
import { Alert } from "@material-ui/lab";

const normaliseOptions = formularies => map(formularies, d => ({
  label: d.formularyName,
  value: `${d.formularyId}`,
}));

export default function FormularySelector({ required, onChange, orgUnitId, ...other }) {
  const dispatch = useDispatch();
  const { field: { value: selectedValue } } = other;
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [initialOptions, setInitialOptions] = useState(selectedValue ?? []);
  const [options, setOptions] = useState(initialOptions || []);
  const [isOpen, setOpen] = useState(false);

  const onInputChange = (event, value) => setOptions(filter(initialOptions, x => includes(lowerCase(x.label), lowerCase(value))));

  const onSelectChange = value => {
    if (onChange) {
      onChange(value);
    }
    setOpen(false);
    setLoading(false);
  };

  const multipleProps = other.multiple && {
    handleOnChange: (newValue, name, setFieldValue) => setFieldValue(name, newValue),
  };

  return (
    <>
      <AutoComplete
        loading={loading}
        options={options}
        onInputChange={onInputChange}
        filterSelectedOptions
        onOpen={() => {
          setOpen(true);
          setLoading(true);
          dispatch(fetchFormularies(orgUnitId)).then(res => {
            setOptions(res.error ? [] : normaliseOptions(res.payload));
            setInitialOptions(res.error ? [] : normaliseOptions(res.payload));
            setApiError(res.error ? "Error Loading Formulary List" : null);
            setLoading(false);
          });
        }}
        onBlur={() => setOpen(false)}
        required={required}
        renderOption={({ label, value }) => (value ? (
          <div className="flex flex-col flex-1">
            <Typography className="font-bold">{label}</Typography>
            <Typography variant="caption" color="textSecondary">{value}</Typography>
            {last(options)?.value !== value && <Divider />}
          </div>
        ) : label)}
        onChange={onSelectChange}
        open={isOpen}
        onClose={() => setOptions(initialOptions)}
        {...multipleProps}
        {...other}
      />
      {apiError && <Alert severity="warning">{apiError}</Alert>}
    </>
  );
}
